import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";

const IconTwitch = ({ size = "2x", theme = "light" }) => {
  const iconColor = (theme = "light" ? "#6441a5" : "#6441a5");
  return (
    <FontAwesomeIcon
      icon={faTwitch}
      size={size}
      style={{ color: iconColor}}
    />
  );
};

export default IconTwitch;
