import React from "react";

import Book from "./Book";

import bookData from "../../data/booksData.json";

const BookStore = () => {
  return (
    <>
      <div className="generalHeader">Book</div>
      {bookData.map((data) => {
        return <Book key={data.id} data={data} />;
      })}
    </>
  );
};

export default BookStore;
