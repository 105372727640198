// react imports
import React, { useEffect, useState } from "react";

// libraries
import { Button, Container } from "react-bootstrap";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

// custom pages
import Home from "./components/Home";
import Books from "./components/Books";
import Store from "./components/Store";
import Blog from "./components/Blog";
import About from "./components/About";
import Contact from "./components/Contact";
import Support from "./components/Support";
import SignUp from "./components/SignUp";
import LogIn from "./components/LogIn";
import LetsPlay from "./components/LetsPlay";
import GameLibrary from "./components/LetsPlay/GameLibrary";

// custom components
import Header from "./components/elements/Header";
import Navigation from "./components/elements/Navigation";
import Footer from "./components/elements/Footer";

// css
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/draft-js/dist/Draft.css";
import "./styles/styles.css";

// react-social may allow easy posting on blog
import { DraftJS } from "./components/elements/DraftJS";
import useAuthContext from "./hooks/context/useAuthContext";

import { useTitle } from "./hooks";

const App = () => {
  useTitle("JPEdgar.com | Home");
  const { authState } = useAuthContext();
  return (
    <>
      <Container>
        <Header />
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Navigate to="/" />} />
          <Route path="/books" element={<Books />} />
          <Route path="/store" element={<Store />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/support" element={<Support />} />







          <Route path="/lets-play" element={<LetsPlay />} />
          <Route path="/game-library" element={<GameLibrary />} />
        </Routes>
        <Footer />
      </Container>
    </>
  );
};

export default App;
