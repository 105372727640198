import BLOG_TYPES from "../constants/types/blogTypes";

const blogReducer = (state, action) => {
  // console.log("in blogReducer - data = ", { state, action });
  switch (action.type) {
    case BLOG_TYPES.SET_POSTS:
      return action.payload;
    case BLOG_TYPES.CREATE_POST:
      return [...state, action.payload];
    case BLOG_TYPES.DELETE_POST:
      return state.filter((x) => x._id !== action.payload._id);
    case BLOG_TYPES.UPDATE_POST:
      return state.map((x) =>
        action.payload._id === x._id ? action.payload : x
      );
    default:
      console.log("dispatch - else");
      return state;
  }
};

export default blogReducer;
