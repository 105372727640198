import useAuthContext from "./context/useAuthContext";

import AUTH_TYPES from "../constants/types/authTypes";

const useLogout = () => {
  const { dispatch: authDispatch } = useAuthContext();

  const logout = () => {
    localStorage.removeItem("jpedgar-auth");
    authDispatch({ type: AUTH_TYPES.LOGOUT });
  };

  return { logout };
};

export default useLogout;
