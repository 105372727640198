import React, { useState, useEffect } from "react";

import { Form, Button, Container } from "react-bootstrap";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

import { DraftJS } from "../elements/DraftJS";
import { useBlogPosts, useEditor } from "../../hooks/";

const CreatePost = ({ activeID, setActiveID }) => {
  const { blogPosts } = useBlogPosts();
  const { resetForm, handleEditorChange, handleSubmit, error } = useEditor();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [formData, setFormData] = useState({ title: "", body: "", tags: "" });

  const handleClear = () => {
    setFormData({ title: "", body: "", tags: "" });
    setEditorState(EditorState.createEmpty());
    setActiveID("");
    resetForm();
  };

  const handleChange = (e) =>
    setFormData((curr) => ({ ...curr, [e.target.name]: e.target.value }));

  const submitForm = (formData, activeID) => {
    handleSubmit(formData, activeID);
    handleClear();
  };

  useEffect(() => {
    handleEditorChange({
      ...formData,
      body: convertToRaw(editorState.getCurrentContent()),
    });
  }, [editorState, formData]);

  useEffect(() => {
    if (activeID) {
      const activePost = blogPosts.find((x) => x._id === activeID);

      setEditorState(
        EditorState.createWithContent(
          convertFromRaw({ ...activePost.body, entityMap: {} })
        )
      );
      setFormData(activePost);
    }
  }, [activeID]);

  return (
    <>
      {/* <Container style={{borderColor: "red", borderStyle: "solid", padding: "0px"}}> */}
      <Container
        style={{
          borderColor: "red",
          borderStyle: `${activeID ? "solid" : "hidden"}`,
          padding: "0px",
        }}
      >
        <Form>
          <Form.Control
            type="text"
            value={formData.title}
            name="title"
            onChange={handleChange}
            placeholder="Post title"
          />
          <Form.Control
            type="text"
            value={formData.tags}
            name="tags"
            onChange={handleChange}
            placeholder="Tags (separate by comma)"
          />
          <DraftJS editorState={editorState} setEditorState={setEditorState} />
          <div className="justify-content-between d-flex mt-1">
            <Button variant="warning" onClick={() => handleClear()}>
              Clear
            </Button>
            <Button
              onClick={() =>
                submitForm({ ...formData, body: editorState }, activeID)
              }
            >
              Submit
            </Button>
          </div>
          {error && <div style={{ color: "red" }}>{error}</div>}
        </Form>
      </Container>
    </>
  );
};

export default CreatePost;
