import React from "react";

import { Row } from "react-bootstrap";

import Game from "./Game";

import gameLibrary from "../../../data/gameLibraryData.json";

const index = () => {
  return (
    <Row gap={3}>
      {gameLibrary.map(
        (gameData) => gameData.visibleFlag && <Game gameData={gameData} key={gameData.id} />
      )}
    </Row>
  );
};

export default index;
