import React from "react";

// libraries
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

const StoreUpdateSection = () => {
  return (
    <>
      <div className="text-center mb-2 generalHeader">
        Store has been updated with <br />
        BoA Bk3 products.
      </div>
      <Link to="/store">
        <Image
          alt="BoA, Jace Bk3 black unisex tee"
          src="images/Apparel-BoA-JaceBk3-Tee.jpg"
          style={{ maxHeight: "250px" }}
          thumbnail
        />
      </Link>
      <div className="generalText">
        Check out the store to see the official Bloodlines of Atmos apparel --
        hoodies and t-shirts are available in various sizes.
      </div>
    </>
  );
};

export default StoreUpdateSection;
