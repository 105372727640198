const About = () => {
  return (
    <>
      <div className="generalHeader headerBG px-4">About</div>
      <div className="generalText">
        J. P. Edgar is an American author born in Sacramento, California in
        1980. He went to college to obtain his Associate's Degree in Information
        Technology, then got his Bachelor's Degree in Game Design in 2010. A man
        of many faces, J. P. Edgar is a software developer, technical artist,
        musician, and game designer. He's currently working on a few YouTube
        channels and a live stream, in pre-production for his next Bloodlines of
        Atmos (BoA) book, and is close to releasing two more books out of the
        BoA series.
      </div>
    </>
  );
};

export default About;
