import React from "react";

import { Col, Button, Image } from "react-bootstrap";

const Apparel = ({ data }) => {
  return (
    <>
      <Col xs={12} sm={6} md={4} className="mb-1">
        <a href={data.link} target="_blank" rel="noreferrer">
          <Image alt={data.altText} src={data.image} thumbnail />
        </a>
        <Button style={{ width: "100%" }}>{data.altText}</Button>
      </Col>
    </>
  );
};

export default Apparel;
