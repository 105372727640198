import React from "react";

// libraries
import { Row, Container } from "react-bootstrap";

// components
import Slogan from "./Slogan";
import RightSidePane from "./RightSidePane";
import LeftSidePane from "./LeftSidePane";

const Home = () => {
  return (
    <Container>
      <Row>
        <Slogan />
        <LeftSidePane />
        <RightSidePane />
      </Row>
    </Container>
  );
};

export default Home;
