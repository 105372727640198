import React from "react";

// components
import BookStore from "./BookStore";
import ApparelStore from "./ApparelStore";

const Store = () => {
  return (
    <>
      <div className="generalHeader headerBG px-4">Store</div>
      <BookStore />
      <ApparelStore />
    </>
  );
};

export default Store;
