import React, { useState, useEffect } from "react";

import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

import useEditorContext from "./context/useEditorContext";
import EDITOR_TYPES from "../constants/types/editorTypes";
import useBlogPosts from "../hooks/useBlogPosts";

import { cloneDeep } from "../utilities";

const useEditor = () => {
  const { createNewPost, editBlogPost, refreshBlogState } = useBlogPosts();
  const { editorState, dispatch: editorDispatch } = useEditorContext();
  const [error, setError] = useState(null);
  // const [loadingFlag, setLoadingFlag] = useState(null);
  const blogMinLength = 1;

  const resetForm = () => {
    const payloadState = {
      title: "",
      body: EditorState.createEmpty(),
      file: "",
      tags: [],
    };
    editorDispatch({ type: EDITOR_TYPES.SET_EDITOR, payload: payloadState });
  };

  const handleEditorChange = (formData = {}) => {
    editorDispatch({ type: EDITOR_TYPES.SET_EDITOR, payload: formData });
  };

  const getBlogCharacterLength = (data) => {
    return data.getCurrentContent().getPlainText().length;
  };

  const cropPlainText = (postData, maxLength) => {
    const convertedPostData = EditorState.createWithContent(
      convertFromRaw({ ...postData, entityMap: {} })
    );

    const postDataText = convertedPostData.getCurrentContent().getPlainText();

    let returnData = "";
    if (getBlogCharacterLength(convertedPostData) > maxLength) {
      returnData = postDataText.slice(0, maxLength) + "...";
    } else {
      returnData = postDataText;
    }
    return returnData;
  };

  const createSnippet = (postData, maxLength) => {
    const revisedPostData = cloneDeep(postData);

    revisedPostData.blocks["0"].text = "";
    if (revisedPostData.blocks["1"].text.length <= 0)
      revisedPostData.blocks["1"].text = "";

    const returnData = cropPlainText(revisedPostData, maxLength);

    return returnData;
  };

  const handleSubmit = async (formData = {}, activeID = "") => {
    setError(null);

    if (!formData.title) {
      setError("No title present.");
      return;
    }

    if (getBlogCharacterLength(formData.body) < blogMinLength) {
      setError(`Body must be at least ${blogMinLength} characters long.`);
      return;
    }

    if (!activeID) {
      createNewPost({
        ...formData,
        body: convertToRaw(formData.body.getCurrentContent()),
        tags: [],
      });
    } else {
      editBlogPost(activeID, {
        ...formData,
        body: convertToRaw(formData.body.getCurrentContent()),
        tags: [],
      });
    }
  };

  const setEditorDetails = (post) => {
    // console.log("post = ", post);
    const payloadState = {
      title: post.title,
      body: EditorState.createWithContent(convertFromRaw(post.body)),
      file: post.file,
      tags: post.tags,
    };
    editorDispatch({ type: EDITOR_TYPES.SET_EDITOR, payload: payloadState });
  };

  return {
    cropPlainText,
    createSnippet,
    setEditorDetails,
    resetForm,
    handleEditorChange,
    editorState,
    handleSubmit,
    // loadingFlag,
    error,
  };
};

export default useEditor;
