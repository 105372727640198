import axios from "axios";

const BASE_URL = "https://jpedgar-server.onrender.com"; // deploy
// const BASE_URL = "http://localhost:4000"; // dev
const authURL = `${BASE_URL}/api/auth`;
const blogURL = `${BASE_URL}/api/blog`;

// auth
const signUp = async (email = "", password = "") =>
  axios.post(`${authURL}/signup`, { email, password });
const login = async (email = "", password = "") =>
  axios.post(`${authURL}/login`, { email, password });
const updatePassword = async (
  email = "",
  password = "",
  newPassword = "",
  token = ""
) =>
  axios.patch(
    `${authURL}/update-password`,
    { email, password, newPassword },
    { headers: { Authorization: `bearer ${token}` } }
  );
const updateEmail = async (
  email = "",
  password = "",
  newEmail = "",
  token = ""
) => {
  return axios.patch(
    `${authURL}/update-email`,
    { email, password, newEmail },
    { headers: { Authorization: `bearer ${token}` } }
  );
};
const deleteAccount = async (email) =>
  axios.delete(`${authURL}/delete`, { email });

// blog
const getAllPosts = async () => axios.get(blogURL);
const editPost = async (id, postEdits) =>
  axios.patch(blogURL, { _id: id, ...postEdits });
const deletePost = async (id) => axios.delete(blogURL, { _id: id });
const createPost = async (postData) => await axios.post(blogURL, postData);

export {
  signUp,
  login,
  updatePassword,
  updateEmail,
  deleteAccount,
  getAllPosts,
  editPost,
  deletePost,
  createPost,
};
