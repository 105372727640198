import { useContext } from "react";

import { BlogContext } from "../../context/BlogContext";

const useBlogContext = () => {
  const context = useContext(BlogContext);

  if (!context) throw Error("Context error - useBlogContext out-of-scope.");

  return context;
};

export default useBlogContext;
