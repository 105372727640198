import React from "react";

// libraries
import { Row, Navbar, Image, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <Container>
      <Row>
        <Navbar>
          <Navbar.Brand as={Link} to="/">
            <div className="d-flex align-items-center">
              <Image
                alt="JPEdgar logo"
                src="images/Logo-JPEdgar.png"
                height="70"
                className="logo"
              />
              <span className="title">J. P. Edgar</span>
            </div>
          </Navbar.Brand>
        </Navbar>
      </Row>
    </Container>
  );
};

export default Header;
