import React, { useState } from "react";

import useBlogContext from "./context/useBlogContext";
import useEditorContext from "./context/useEditorContext";

import { useEditor } from "./";

import BLOG_TYPES from "../constants/types/blogTypes";

import {
  getAllPosts as getAllBlogPosts,
  deletePost,
  editPost,
  createPost,
} from "../actions/blog";
import EDITOR_TYPES from "../constants/types/editorTypes";

const useBlogPosts = () => {
  const { editorState, dispatch: editorDispatch } = useEditorContext();
  const { blogState: blogPosts, dispatch: blogDispatch } = useBlogContext();
  // const { setEditorDetails } = useEditor();
  const [error, setError] = useState(null);
  const [loadingFlag, setLoadingFlag] = useState(null);

  const refreshBlogState = async () => {
    setLoadingFlag(true);
    setError(null);

    const blogResponse = await getAllBlogPosts();

    if (blogResponse.error) {
      setError(blogResponse.error);
      setLoadingFlag(false);
      return;
    }

    const { data: blogData } = blogResponse;

    if (blogResponse.error) {
      setError(blogResponse.error);
      setLoadingFlag(false);
    } else {
      const { data } = blogData;
      // console.log("blogData = ", blogData);
      blogDispatch({ type: BLOG_TYPES.SET_POSTS, payload: data }); // saves  data to state

      setLoadingFlag(false);
    }
  };

  const deleteBlogPost = async (id) => {
    setLoadingFlag(true);
    setError(null);

    if (!id) {
      setError("A post ID is required.");
      setLoadingFlag(false);
      return;
    }

    const { data } = await deletePost(id);
    blogDispatch({ type: BLOG_TYPES.DELETE_POST, payload: data });
  };

  const editBlogPost = async (id, updates) => {
    setLoadingFlag(true);
    setError(null);

    if (!id) {
      setError("A post ID is required.");
      setLoadingFlag(false);
      return;
    }

    if (!updates) {
      setError("No updates present.");
      setLoadingFlag(false);
      return;
    }

    await editPost(id, { ...updates });
    blogDispatch({
      type: BLOG_TYPES.UPDATE_POST,
      payload: { ...updates },
    });

    setLoadingFlag(false);
  };

  const createNewPost = async (postData) => {
    setLoadingFlag(true);
    setError(null);

    if (!postData) {
      setError("No data");
      setLoadingFlag(false);
      return;
    }

    const { data } = await createPost({ ...postData });
    if (!data) {
      setError("something went wrong in creating the post");
      setLoadingFlag(false);
      return;
    }

    blogDispatch({
      type: BLOG_TYPES.CREATE_POST,
      payload: { ...data },
    });

    setLoadingFlag(false);
  };

  return {
    refreshBlogState,
    deleteBlogPost,
    editBlogPost,
    createNewPost,
    blogPosts,
    loadingFlag,
    error,
  };
};

export default useBlogPosts;
