import React from "react";

// libraries
import { Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

// custom components
import Line from "../../elements/Line";

import BoACovers from "../../../images/BoA1-3_FrontFan.jpg";

const Showcase = () => {
  const UpperShowcase = () => {
    return (
      <Row className="text-center generalText mb-4">
        <div className="bookHeader">
          Bloodlines of Atmos, <br />
          the Story of Jace, Books 1 - 3
        </div>
        <div>Now available!</div>
        <div>
          <Link to="/store" className="bookLink">
            Physical soft-bound, digitial, and audio copies ready for your
            library.
          </Link>
        </div>
        <Link to="/store">
          <Row className="justify-content-center">
            <Col xs={10}>
              <Image
                alt="BoA, Jace Bk3"
                src={BoACovers}
                style={{ maxHeight: "500px" }}
                thumbnail
              />
            </Col>
          </Row>
        </Link>
      </Row>
    );
  };

  const LowerShowcase = () => {
    return (
      <>
        <Row className="text-center generalText">
          <div className="bookHeader">
            Bloodlines of Atmos, The Story of Jace
          </div>
        </Row>

        <Row className="text-center generalText">
          <Col>
            <div className="bookHeader">Book 1 - Sanctuary</div>
          </Col>
          <Col>
            <div className="bookHeader">Book 2 - Savior</div>
          </Col>
          <Col>
            <div className="bookHeader">Book 3 - Redemption</div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Link to="/store">
              <Image
                alt="BoA, Jace Bk1"
                src="images/BoA_JaceBk1-Cover.jpg"
                style={{ maxHeight: "350px" }}
                thumbnail
              />
            </Link>
          </Col>
          <Col>
            <Link to="/store">
              <Image
                alt="BoA, Jace Bk2"
                src="images/BoA_JaceBk2-Cover.jpg"
                style={{ maxHeight: "350px" }}
                thumbnail
              />
            </Link>
          </Col>
          <Col>
            <Link to="/store">
              <Image
                alt="BoA, Jace Bk2"
                src="images/BoA_JaceBk3-Cover.jpg"
                style={{ maxHeight: "350px" }}
                thumbnail
              />
            </Link>
          </Col>
        </Row>
        <Row className="text-center generalText">
          <div className="fs-4 mt-1">
            Are available in physical softbound, digital, and audio.
          </div>
        </Row>
      </>
    );
  };

  return (
    <>
      <UpperShowcase />
      <Line />
      <LowerShowcase />
    </>
  );
};

export default Showcase;
