import React from "react";

const Slogan = () => {
  return (
    <>
      <div className="slogan headerBG text-center">
        I write whatever comes to mind. Mostly fantasy. Some times a touch of
        sci-fi. Maybe a bit of both.
      </div>
    </>
  );
};

export default Slogan;
