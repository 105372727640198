import { useContext } from "react";

import { EditorContext } from "../../context/EditorContext";

const useEditorContext = () => {
  const context = useContext(EditorContext);

  if (!context) throw Error("Context error - useEditorContext out-of-scope.");

  return context;
};

export default useEditorContext;
