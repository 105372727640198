import React from "react";

import { Button, Card, Col } from "react-bootstrap";

const Game = ({ gameData }) => {
  /*
    {
    "id": "game-library-001",
    "title": ".hack//G.U. Last Recode",
    "console": "PS4",
    "prereq": "",
    "lets-play": {
      "havePlayedFlag": true,
      "letsPlayFlag": false,
      "playlist": "",
      "streamStart": "",
      "streamEnd": "",
      "episodeCount": 0
    },
    "visibleFlag": true,
    "note": ""
  },
  */

  const title = gameData.title;
  const console = gameData.console;
  const havePlayedFlag = gameData.letsPlay.havePlayedFlag;
  const letsPlayFlag = gameData.letsPlay.letsPlayFlag;
  const streamStart = gameData.letsPlay.streamStart;
  const streamEnd = gameData.letsPlay.streamEnd;
  const episodeCount = gameData.letsPlay.episodeCount;
  const playlist = gameData.letsPlay.playlist;

  return (
    <Card className="p-3 m-1" as={Col} xs={12} sm={6} md={3}>
      <Card.Title className="d-flex">"{title}"</Card.Title>
      <Card.Text className="ms-1">on the {console}</Card.Text>
      {havePlayedFlag && (
        <Card.Text className="m-0 bg-light text-center">
          I<i> have </i>played this game before.
        </Card.Text>
      )}
      {letsPlayFlag && (
        <div className="my-2">
          <Card.Text className="m-0">
            Streamed from {streamStart} to {streamEnd}
          </Card.Text>
          <Card.Text>Episode Count: {episodeCount}</Card.Text>
          <Button href={playlist}>Go to YouTube playlist</Button>
        </div>
      )}
    </Card>
  );
};

export default Game;
