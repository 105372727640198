import React, { useState, useEffect } from "react";

// libraries
import { Card, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import {
  Editor,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
  convertFromRaw,
  convertToRaw,
} from "draft-js";

import { useBlogPosts, useAuthDetails, useEditor } from "../../../../hooks";

const Post = ({ post, setActiveID }) => {
  const { deleteBlogPost } = useBlogPosts();
  const { authDetails } = useAuthDetails();
  const [editorState] = useState(
    EditorState.createWithContent(
      convertFromRaw({ ...post.body, entityMap: {} })
    )
  );

  const handleTags = (tags = []) => {
    return <>{tags.map((tag) => `#${tag}`)}</>;
  };

  const handleDelete = (post) => {
    // console.log("post= ", post);
    deleteBlogPost(post._id);
  };

  return (
    <>
      <Card className="mb-2">
        <Card.Header>
          <div className="d-flex justify-content-between">
            <h4>{post.title}</h4>

            {authDetails.email && (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setActiveID(post._id)}
              >
                <FontAwesomeIcon icon={faEllipsisH} />
              </span>
            )}
          </div>
          <div>
            {post.editedAt ? (
              <Card.Text style={{ fontSize: ".75rem" }}>
                edited {moment(post.editedAt).fromNow()}
              </Card.Text>
            ) : (
              <Card.Text style={{ fontSize: ".75rem" }}>
                created {moment(post.createdAt).fromNow()}
              </Card.Text>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          {/* 
          <Card.Text>{post.body}</Card.Text>
          {post.selectedFile && (
            <Image
              src={post.selectedFile}
              alt="test"
              style={{ maxHeight: "250px" }}
            />
          )}
        */}
          <Card.Text>Tags: {handleTags(post.tags)}</Card.Text>

          <Editor editorState={editorState} readOnly={true} />
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end">
          {authDetails.email && (
            <FontAwesomeIcon
              icon={faTrash}
              style={{ cursor: "pointer" }}
              // onClick={() => deleteBlogPost(post._id)}
              onClick={() => handleDelete(post)}
            />
          )}
        </Card.Footer>
      </Card>
    </>
  );
};

export default Post;
