import React from "react";

// libraries
import { Form, Button } from "react-bootstrap";

const Contact = () => {
  return (
    <>
      <div className=" disabled generalHeader headerBG px-4">Contact</div>
      <div className=" disabled generalWarning text-center">
        Contact currently disabled during construction process.
      </div>
      <Form>
        <Form.Group disabled className=" disabled mb-3" controlId="formName">
          <Form.Label disabled className=" disabled generalText ">
            Name
          </Form.Label>
          <Form.Control
            disabled
            type="text"
            placeholder="Enter name"
            className="disabled"
          />
        </Form.Group>

        <Form.Group disabled className=" disabled mb-3" controlId="formEmail">
          <Form.Label disabled className=" disabled generalText">
            Email address
          </Form.Label>
          <Form.Control
            disabled
            type="email"
            placeholder="Enter email"
            className="disabled"
          />
          <Form.Text disabled className=" disabled text-muted generalText">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>
        <Form.Group disabled className=" disabled mb-3" controlId="formMessage">
          <Form.Label disabled className=" disabled generalText">
            Message
          </Form.Label>
          <Form.Control
            disabled
            as="textarea"
            rows={3}
            placeholder="Enter message"
            className="disabled"
          />
        </Form.Group>
        <Button disabled variant="primary" type="submit" className="disabled">
          Submit
        </Button>
      </Form>
    </>
  );
};

export default Contact;
