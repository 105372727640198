import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

// libraries
import moment from "moment";
import { useEditor } from "../../../hooks";

const BlogSnippet = ({ post }) => {
  const [convertedText, setConvertedText] = useState("");
  const { createSnippet } = useEditor();

  useEffect(() => {
    const croppedText = createSnippet(post.body, 75); // (postData, maxLength)
    setConvertedText(croppedText);
  }, []);

  return (
    <Link to="/blog" style={{ textDecoration: "none" }}>
      <div className="mt-2">
        <div className="text-center">
          <div className="d-flex justify-content-between">
            <span className="generalHeader">{post.title}</span>
            <span className="mx-2 generalText" style={{ fontSize: "0.75rem" }}>
              {moment(post.createdAt).fromNow()}
            </span>
          </div>
        </div>
        <div className="generalText">{convertedText}</div>
      </div>
    </Link>
  );
};

export default BlogSnippet;
