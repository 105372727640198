import React, { createElement } from "react";

// libraries
import { Image, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

// data
import bookData from "../../data/booksData.json";

const Books = () => {
  const Book = ({ data }) => {
    const InsertElement = ({ desc }) => {
      return <div className="generalText"> {createElement("p", {}, desc)}</div>;
    };

    return data.releaseInfo.released ? (
      <>        
        <Row className="mb-4" > 
          <Col xs={12} md={8}>
            <Link to="/store">
              <Image
                alt={data.imgAltText}
                src={data.bookCover}
                thumbnail
                className="bookImg d-flex mx-auto"
              />
            </Link>
            <Row>
              <Col xs={12} md={6} className="my-1">
                <Button style={{ width: "100%" }}>Read Snippit</Button>
              </Col>
              <Col
                xs={12}
                md={6}
                className="d-flex justify-content-end mt-1 mb-4"
              >
                <Button as={Link} to="/store" style={{ width: "100%" }}>
                  Purchase
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={4}>
            <div className="bookHeader">{data.bookName}</div>
            {data.bookDescription.map((desc, idx) => {
              return <InsertElement key={idx} desc={desc} />;
            })}
          </Col>
        </Row>
      </>
    ) : null;
  };
  return (
    <>
      <div className="generalHeader headerBG px-4">Books</div>
      {bookData.map((data) => {
        return <Book key={data.id} data={data} />;
      })}
    </>
  );
};

export default Books;
