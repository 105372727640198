import React, { useState, useEffect } from "react";

// custom components
import Post from "./Post";
import LoadingSpinner from "../../elements/LoadingSpinner";

// custom hooks
import { useBlogPosts } from "../../../hooks/";

const Posts = ({ setActiveID }) => {
  const { blogPosts } = useBlogPosts();
  const [sortedPosts, setSortedPosts] = useState(blogPosts);

  useEffect(() => {
    setSortedPosts((curr) =>
      [...curr].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    );
  }, []);

  // add in search, sort, and tag functions

  return (
    <>
      {sortedPosts.length > 0 ?
        sortedPosts.map((blogPost, index) => (
          <Post
            key={blogPost._id ? blogPost._id : `blog-post-${index}`}
            post={blogPost}
            setActiveID={setActiveID}
          />
        )) : <LoadingSpinner/>}
    </>
  );
};

export default Posts;
