import EDITOR_TYPES from "../constants/types/editorTypes";

const editorReducer = (state, action) => {
  // console.log("in editorReducer - data = ", { state, action });
  switch (action.type) {
    case EDITOR_TYPES.SET_EDITOR:
      return action.payload;
    default:
      console.log("dispatch - else");
      return state;
  }
};

export default editorReducer;
