import React from "react";

import Schedule from "./Schedule";

const LetsPlay = () => {
  return (
    <>
      <Schedule />
    </>
  );
};

export default LetsPlay;
