import React from "react";

import { Row, Col, Button, Image, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Book = ({ data }) => {
  return data.releaseInfo.released ? (
    <Container className="mb-4">
      <Row>
        <Col xs={6}>
          <Link to="/books">
            <Image
              alt={data.imgAltText}
              src={data.bookCover}
              thumbnail
              style={{ maxHeight: "400px" }}
              className="bookImg d-flex mx-auto"
            />
          </Link>
          <div className="generalText text-center">{data.bookName}</div>
        </Col>
        <Col xs={3}>
          <Row>
            <div className="generalHeader">Print</div>
            {data.physical.available &&
              data.physical.links.map((bookLink, index) => {
                return (
                  <Button
                    key={`bookPhysicalDetails-${index}`}
                    href={bookLink.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {bookLink.name}
                  </Button>
                );
              })}
          </Row>

          <Row>
            {data.audio.available && (
              <>
                <div className="generalHeader">Audiobook</div>
                {data.audio.links.map((bookLink, index) => {
                  return (
                    <Button
                      key={`bookAudioDetails-${index}`}
                      href={bookLink.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {bookLink.name}
                    </Button>
                  );
                })}
              </>
            )}
          </Row>
        </Col>

        <Col xs={3}>
          <div className="generalHeader">Digital</div>
          <div className="d-grid">
            {data.digital.available &&
              data.digital.links.map((bookLink, index) => {
                return (
                  <Button
                    key={`bookDigitalDetails-${index}`}
                    href={bookLink.link}
                    className="mb-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {bookLink.name}
                  </Button>
                );
              })}
          </div>
        </Col>
      </Row>
    </Container>
  ) : null;
};

export default Book;
