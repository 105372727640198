import * as api from "../api";

const getAllPosts = async () => {
  try {
    const data = await api.getAllPosts();
    return data;
  } catch (error) {
    // console.log(error);
    return error.response.data;
  }
};

// postEdits = {title, body}
const editPost = async (id, postEdits) => {
  try {
    const data = await api.editPost(id, postEdits);
    return data;
  } catch (error) {
    // console.log(error);
    return error.response.data;
  }
};

const deletePost = async (id) => {
  try {
    const data = await api.deletePost(id);
    return data;
  } catch (error) {
    // console.log(error);
    return error.response.data;
  }
};

const createPost = async (postData) => {
  try {
    const data = await api.createPost(postData);
    return data;
  } catch (error) {
    // console.log(error);
    return error.response.data;
  }
};

export { getAllPosts, editPost, deletePost, createPost };
