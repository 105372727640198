const Support = () => {
  return (
    <>
      <h3 className="generalHeader">Support</h3>
      <p className="generalText">
        Thank you for your interest in reporting a problem. I'm still building
        this website and it'll be going through numerous revisions as time
        progresses.
      </p>
    </>
  );
};

export default Support;
