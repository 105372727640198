import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

const IconFacebook = ({ size = "2x", theme = "light" }) => {
  const iconColor = (theme = "light" ? "#4267B2" : "#898F9C");
  return (
    <FontAwesomeIcon
      icon={faFacebook}
      size={size}
      style={{ color: iconColor }}
    />
  );
};

export default IconFacebook;
