import React from "react";

// libraries
import { Spinner } from "react-bootstrap";

const LoadingSpinner = () => {
  return (
    <div className="text-center">
      <Spinner animation="border" variant="light" />
    </div>
  );
};

export default LoadingSpinner;
