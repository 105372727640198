import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

const IconYouTube = ({ size = "2x", theme = "light" }) => {
  const iconColor = theme === "light" ? "FF0000" : "282828";
  return (
  

    <FontAwesomeIcon
      icon={faYoutube}
      size={size}
      style={{ color: iconColor }}
      />
     
  );
};

export default IconYouTube;
