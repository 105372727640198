import React, { useState } from "react";

// libraries
import { Row, Col, Button } from "react-bootstrap";

// custom components
import CreatePost from "./CreatePost";
import Posts from "./Posts";

import { useAuthDetails, useBlogPosts } from "../../hooks";

const Blog = () => {
  const { authDetails } = useAuthDetails();
  const { blogPosts } = useBlogPosts();
  const [activeID, setActiveID] = useState("");
  return (
    <>
      <div className="generalHeader headerBG px-4">Blog</div>
      {/* <div className="generalText">JPEdgar's writer's blog.</div> */}
      {/* <Button onClick={() => console.log(blogPosts)}>Log blogPosts</Button> */}
      <Row>
        {authDetails.email ? (
          <>
            <Col xs={7}>
              <Posts setActiveID={setActiveID} />
            </Col>
            <Col>
              <CreatePost activeID={activeID} setActiveID={setActiveID} />
            </Col>
          </>
        ) : (
          <>
            <Col>
              <Posts />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default Blog;
