import React from "react";

// libraries
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

// components
import Line from "./Line";
import IconFacebook from "./Icon_Facebook";

const Footer = () => {
  return (
    <>
      <Container>
        <Row>
          <Line />
          <Row>
            <div className="underConstruction text-center">
              This website is currently under construction
            </div>
          </Row>
          <Row>
            <div className="text-centerd generalText">Visit me on Facebook</div>
          </Row>
          <Row className="d-block">
            <span>
              <a
                href="http://www.facebook.com/jpedgarbooks"
                className="btn btn-social"
                target="_blank"
                rel="noreferrer"
              >
                <IconFacebook />
              </a>
            </span>
          </Row>

          <Row className="generalText smallText">
            <Col>
              Copyright &copy;2016-2024, J.P.Edgar. All rights reserved.
            </Col>
            <Col className="d-flex justify-content-end">
              <Link to="/support" className=" bookLink smallText">
                Report a problem
              </Link>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default Footer;
