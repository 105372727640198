// react components
import React from "react";
import ReactDOM from "react-dom";

// libraries
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

// components
import App from "./App";

import GlobalWrapper from "./context/GlobalWrapper";

if (process.env.NODE_ENV === "production") disableReactDevTools();

ReactDOM.render(
  <GlobalWrapper>
    <App />
  </GlobalWrapper>,
  document.getElementById("root")
);
