import React from "react";

// libraries
import { Col } from "react-bootstrap";

// custom components
import Line from "../../elements/Line";
import StoreUpdateSection from "./StoreUpdateSection";
import BlogSnippets from "./BlogSnippets";

const RightSidePane = () => {
  return (
    <Col xs={12} md={4}>
      <BlogSnippets />
      <Line />
      <StoreUpdateSection />
    </Col>
  );
};

export default RightSidePane;
