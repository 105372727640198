import React from "react";

// custom components
import BlogSnippet from "./BlogSnippet";

import { useBlogPosts } from "../../../hooks";
import LoadingSpinner from "../../elements/LoadingSpinner";

const BlogSnippets = () => {
  const { blogPosts } = useBlogPosts();

  let sortedPostsByNewest = [];
  if (Array.isArray(blogPosts)) {
    sortedPostsByNewest = [...blogPosts].reverse();
  }

  return sortedPostsByNewest.length > 0 ? sortedPostsByNewest.map((post, index) => {
    return index < 3 ? (
      <BlogSnippet key={`${post._id}-${index}`} post={post} />
    ) : null;
  }) : <LoadingSpinner/>
};

export default BlogSnippets;
