import React from "react";

// libraries
import { Col } from "react-bootstrap";

// custom components
import Showcase from "./Showcase";

const LeftSidePane = () => {
  return (
    <Col xs={12} md={8}>
      <Showcase />
    </Col>
  );
};

export default LeftSidePane;
