import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

const IconFilter = ({ size = "2x", theme = "light" }) => {
  const iconColor = (theme = "light" ? "#1DA1F2" : "");
  return (
    <FontAwesomeIcon icon={faFilter} size={size} style={{ color: iconColor }} />
  );
};

export default IconFilter;
