import React, { useState, useEffect } from "react";

import { Row, Col, Button, Form } from "react-bootstrap";

import Apparel from "./Apparel";
import IconFilter from "../elements/Icon_Filter";

import storeData from "../../data/storeData.json";
import { filter } from "lodash";

const ApparelStore = () => {
  const [filterValue, setFilterValue] = useState({
    showFlag: false,
    tee: true,
    polo: true,
    hoodie: true,
  });
  const [showAllFlag, setShowAllFlag] = useState(false);
  const [hideAllFlag, setHideAllFlag] = useState(false);
  const ETSY_URL = "https://www.etsy.com/shop/JPEdgarBooks";

  const handleChange = (e) => {
    if (filterValue[e.target.name])
      setFilterValue((curr) => ({ ...curr, [e.target.name]: false }));
    else setFilterValue((curr) => ({ ...curr, [e.target.name]: true }));
  };

  const handleClick = () => {
    if (filterValue.showFlag)
      setFilterValue((curr) => ({ ...curr, showFlag: false }));
    else setFilterValue((curr) => ({ ...curr, showFlag: true }));
  };

  const selectAll = () => {
    setFilterValue((curr) => ({
      ...curr,
      tee: true,
      polo: true,
      hoodie: true,
    }));
  };

  const selectNone = () => {
    setFilterValue((curr) => ({
      ...curr,
      tee: false,
      polo: false,
      hoodie: false,
    }));
  };

  useEffect(() => {
    if (
      showAllFlag &&
      filterValue.tee &&
      filterValue.polo &&
      filterValue.hoodie
    )
      setShowAllFlag(false);
    else if (!showAllFlag) setShowAllFlag(true);

    if (
      hideAllFlag &&
      !filterValue.tee &&
      !filterValue.polo &&
      !filterValue.hoodie
    )
      setHideAllFlag(false);
    else if (!hideAllFlag) setHideAllFlag(true);
  }, [filterValue]);

  return (
    <div>
      <div className="generalHeader">Apparel</div>

      <Row>
        <Col>
          <Button href={ETSY_URL} target="_blank" rel="noreferrer">
            Visit the Etsy Page for All Apparel
          </Button>
        </Col>
      </Row>

      <Row className="my-2">
        <div className="d-flex align-items-center">
          <div
            className="me-3"
            onClick={() => handleClick()}
            style={{ cursor: "pointer" }}
          >
            <IconFilter size="2x" />
          </div>

          {filterValue.showFlag && (
            <>
              {showAllFlag && (
                <Button onClick={() => selectAll()} className="mx-2">
                  Select All
                </Button>
              )}
              {hideAllFlag && (
                <Button onClick={() => selectNone()} className="mx-2">
                  Select None
                </Button>
              )}
              <Form>
                <Form.Check
                  inline
                  label="T-Shirts"
                  name="tee"
                  type="checkbox"
                  id="apparel-filter-tee"
                  checked={filterValue.tee}
                  onChange={handleChange}
                  className="text-light"
                />

                <Form.Check
                  inline
                  label="Polos"
                  name="polo"
                  type="checkbox"
                  id="apparel-filter-polo"
                  checked={filterValue.polo}
                  onChange={handleChange}
                  className="text-light"
                />

                <Form.Check
                  inline
                  label="Hoodies"
                  name="hoodie"
                  type="checkbox"
                  id="apparel-filter-hoodie"
                  checked={filterValue.hoodie}
                  onChange={handleChange}
                  className="text-light"
                />
              </Form>
            </>
          )}
        </div>
      </Row>

      <Row>
        {storeData.map((data) => {
          return data.visible && filterValue[data.subtype] ? (
            <Apparel key={data.id} data={data} filterValue={filterValue} />
          ) : null;
        })}
      </Row>
    </div>
  );
};

export default ApparelStore;
