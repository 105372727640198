import React, { createContext, useReducer, useEffect } from "react";

import { editorReducer } from "../reducers";
import EDITOR_TYPES from "../constants/types/editorTypes";
import { EditorState } from "draft-js";

const EditorContext = createContext();

const EditorProvider = ({ children }) => {
  const [editorState, dispatch] = useReducer(editorReducer, {});

  useEffect(() => {
    const tempEditorState = EditorState.createEmpty();
    const payloadState = {
      title: "",
      body: tempEditorState,
      file: "",
      tags: [],
    };
    dispatch({ type: EDITOR_TYPES.SET_EDITOR, payload: payloadState });
  }, []);

  // useEffect(() => { console.log("editorState = "); console.log(editorState); }, [editorState]);

  return (
    <EditorContext.Provider value={{ editorState, dispatch }}>
      {children}
    </EditorContext.Provider>
  );
};

export { EditorContext, EditorProvider, editorReducer };

/* 
const abortController = new AbortController();
axios.get('/foo/bar', {
   signal: controller.signal
}).then(function(response) {
   //...
});
return () => abortController.abort();
*/
