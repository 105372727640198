import { BrowserRouter } from "react-router-dom";

import { BlogProvider } from "./BlogContext";
import { AuthProvider } from "./AuthContext";
import { EditorProvider } from "./EditorContext";

const GlobalWrapper = ({ children }) => {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <EditorProvider>
            <BlogProvider>{children}</BlogProvider>
          </EditorProvider>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
};

export default GlobalWrapper;
