import React, { createContext, useReducer, useEffect } from "react";

import { blogReducer } from "../reducers/";
import { getAllPosts } from "../actions/blog";
import BLOG_TYPES from "../constants/types/blogTypes";

const BlogContext = createContext();

const BlogProvider = ({ children }) => {
  const [blogState, dispatch] = useReducer(blogReducer, []);

  useEffect(() => {
    const refreshBlogState = async () => {
      const blogResponse = await getAllPosts();

      if (blogResponse.error) return;

      const { data: blogData } = blogResponse;

      dispatch({ type: BLOG_TYPES.SET_POSTS, payload: blogData });
    };
    refreshBlogState();
  }, []);

  return (
    <BlogContext.Provider value={{ blogState, dispatch }}>
      {children}
    </BlogContext.Provider>
  );
};

export { BlogContext, BlogProvider, blogReducer };
